<template>
    <div class="elem-table" :style="{ height: height, 'max-height': expand ? 'initial' : 'calc(100vh - 80px)' }">
        <!-- 大标题，覆盖整个宽度 -->
        <div class="title" v-if="title">{{ title }}</div>
        <div class="table-box" ref="table_box">
            <!-- 头部标题区 -->
            <div class="row head" v-if="head">
                <div class="col float-left" v-if="isSelector" style="width:50px;flex-grow:initial">
                    <Checkbox v-if="!isRadio" :indeterminate="selecteds.length > 0 && tableData.length !== selecteds.length" :value="tableData && tableData.length > 0 && tableData.length === selecteds.length" @on-change="onChangeAllSelector"></Checkbox>
                </div>
                <div class="col float-left" v-if="isIndex" style="width:100px;flex-grow:initial">序号</div>
                <div class="col" v-for="(col, idx) in columns" :key="idx" :style="{ width: col._w, 'max-width': col.maxWidth ? col.maxWidth + 'px' : '' }" :class="'float-' + (col.float || col.fixed || '')">
                    <span>{{ col.title || col.key }}</span>
                    <!-- 排序图标 -->
                    <div class="sort-box" v-if="col.sort">
                        <div class="asc" :class="{ ac: col.sort === 'asc' }" @click="onChangeSort(col, 'asc')"></div>
                        <div class="desc" :class="{ ac: col.sort === 'desc' }" @click="onChangeSort(col, 'desc')"></div>
                    </div>
                </div>
            </div>
            <!-- 内容区 -->
            <div class="table-con">
                <div class="row" v-for="(row, idx) in tableData" :key="idx">
                    <!-- 多选框 -->
                    <div class="col float-left" v-if="isSelector" style="width:50px;flex-grow:initial">
                        <Checkbox :class="{ radio: isRadio }" :disabled="selected ? selected({ row, index: idx }) : false" :value="selected ? selected({ row, index: idx }) : selecteds.findIndex(v => v._id === row._id) > -1" @on-change="onChangeSelector($event, row)"></Checkbox>
                    </div>
                    <!-- 序号 -->
                    <div class="col float-left" v-if="isIndex" style="width:100px;flex-grow:initial">{{ idx + 1 }}</div>
                    <div
                        class="col"
                        v-for="(col, i) in columns"
                        :key="i"
                        :style="{ width: col._w, 'max-width': col.maxWidth ? col.maxWidth + 'px' : '', color: getHighlightColor(col, row), cursor: col.click ? 'pointer' : '', ...(col.style || {}) }"
                        :class="'float-' + (col.float || col.fixed || '')"
                        @click="col.click && col.click({ row, index: idx })"
                    >
                        <slot v-if="col.slot" :name="col.slot" :row="row" :col="col"></slot>
                        <!-- 自定义组件类型 -->
                        <ElemRender v-else-if="col.render" :render="col.render" :params="{ row: row, index: idx }"></ElemRender>
                        <div v-else v-html="getValue(col, row)"></div>
                    </div>
                </div>
            </div>
            <!-- 统计区 -->
            <div class="row sum" v-if="isIndex && Object.keys(sums).length > 0">
                <div class="col" style="width:100px;flex-grow:initial">合计</div>
                <div class="col" v-for="(col, idx) in columns" :key="idx" :style="{ width: col._w, 'max-width': col.maxWidth ? col.maxWidth + 'px' : '' }" :class="'float-' + (col.float || col.fixed || '')">
                    <div>{{ typeof sums[col.key] === "number" ? sums[col.key] : "" }}</div>
                </div>
            </div>
        </div>
        <!-- 分页控件 -->
        <div class="page-box" v-show="!!tableApi && !hidePage">
            <Page :current="currentPage" @on-page-size-change="onChangePageSize" :page-size="pageSize" :page-size-opts="[20, 50, 100, 500]" :total="total" size="small" show-total show-elevator show-sizer @on-change="onChangePage" />
        </div>
        <div class="load-box" :class="{ ac: isLoading }">
            <Spin fix></Spin>
        </div>
        <!-- 空数据提示 -->
        <div class="empty-box" v-if="!tableData || tableData.length == 0" :style="{ top: 'calc(50% - 20px + ' + (head ? 21 : 0) + 'px)' }">暂无数据</div>
    </div>
</template>

<script>
import RequestPage from "../../jointly/utils/page"
import Utils from "../../jointly/utils/utils"
import ElemRender from "./header"

export default {
    components: {
        ElemRender,
    },

    data() {
        return {
            // 表格数据
            tableData: null,
            // 当前页码
            currentPage: 1,
            // 页面大小
            pageSize: 20,
            // 总数
            total: 0,
            // 统计
            sums: {},
            // 选择器
            selecteds: [],
            // 是否加载中
            isLoading: false,
            // 隐藏 Page 控制器
            hidePage: false,
        }
    },

    props: {
        // 大标题
        title: {
            type: String,
            required: false,
        },
        // 是否单选
        isRadio: {
            type: Boolean,
            default: false,
        },
        // 是否显示表头
        head: {
            type: Boolean,
            default: true,
        },
        // 列配置
        columns: Array,
        // 接口
        tableApi: {
            type: String,
            required: false,
        },
        // 高度
        height: {
            type: String,
            default: "auto",
        },
        // 数据列表
        dataList: {
            type: Array,
            required: false,
        },
        // 显示选择器
        isSelector: {
            type: Boolean,
            default: false,
        },
        // 显示序号
        isIndex: {
            type: Boolean,
            default: false,
        },
        // 主题色
        color: {
            type: String,
            default: "#2faaf7",
        },
        // 展开（无最大高度限制）
        expand: {
            type: Boolean,
            default: false,
        },
        // 请求方法
        method: {
            type: String,
            default: "get",
        },
        // 处理分页配置
        processPageConfig: {
            type: Function,
            required: false,
        },
        // 分页参数 key 值名称
        keys: {
            type: Object,
            default: new Object(null),
        },
        // 接口数据
        data: null,
        // 数据处理
        processData: {
            type: Function,
            required: false,
        },
        // 使用路径规则
        pathRules: {
            type: Boolean,
            default: true,
        },
        // 已选事件
        selected: {
            type: Function,
            required: false,
        },
    },

    watch: {
        tableApi(v) {
            if (!v) return
            // 写入 url
            this.req.setUrl(v)
        },

        dataList: {
            handler(v) {
                if (!v) return
                this.tableData = JSON.parse(JSON.stringify(v))
                // 处理数据
                this.onProcessData()
            },
            immediate: true,
        },

        columns: {
            handler(v) {
                if (!v) return
                // 处理数据
                this.onProcessData()
            },
            immediate: true,
        },

        selecteds(v) {
            this.$emit("on-selector", {
                tag: "ElemTable",
                value: v,
            })
        },

        tableData(v) {
            this.$emit("on-load", {
                tag: "ElemTable",
                value: v,
            })
        },

        isLoading(v) {
            v && (this.dataList = [])
        },
    },

    mounted() {
        this.req = new RequestPage(this.tableApi, {
            method: this.method.toLowerCase(),
            json: this.method.toLowerCase() === "post", // post 请求时内容为 json 类型
            type: "block", // 块状，不合并
            page: this.pathRules ? Number(this.$core.getUrlParam("page") || 1) : 1,
            size: this.pathRules ? Number(this.$core.getUrlParam("size") || 20) : 20,
            data: this.data,
            keys: this.keys,
            load: !!this.tableApi,
            processPageConfig: this.processPageConfig,
            processData: res => {
                this.hidePage = !res.page && !res.pageSize && !res.data?.currentPage && !res.data?.pageSize

                if (this.processData) {
                    return this.processData(res)
                }
            },
            onChange: data => {
                this.selecteds = []
                this.tableData = data
                this.cacheTableData = JSON.parse(JSON.stringify(data))
                this.currentPage = this.req.getPage()
                this.total = this.req.getTotal()
                // 处理数据
                this.onProcessData()
                // 将分页参数写入 url
                if (this.pathRules) {
                    // 当前页面页码写入 url
                    window.location.href = window.location.href.split("?")[0] + Utils.jsonToParams({ ...Utils.paramsToJson(window.location.href), page: this.req.getPage(), size: this.req.getSize() })
                    // 写入缓存
                    sessionStorage.setItem("preUrl", window.location.hash.replace(/^#/, ""))
                }
            },
            onLoadBefore: () => {
                this.isLoading = true
            },
            onLoadAfter: () => {
                // 滚动到顶部 & 左侧
                this.$refs.table_box.scrollTo(0, 0)
                // 延迟 300ms 关闭
                setTimeout(() => {
                    this.isLoading = false
                }, 300)
            },
            onFail: err => {
                console.log(err)
            },
        })
    },

    methods: {
        /**
         * 处理单元格数据
         */
        onProcessData() {
            for (let i = 0, cs = this.columns; i < cs?.length; i++) {
                const c = cs[i]

                Utils.each(this.tableData, v => {
                    if (!v._id) {
                        v._id = Utils.getUuid()
                    }
                })

                // 排序
                if (c.sort && this.tableData?.length > 0) {
                    if (typeof c.sort === "function") {
                        this.tableData.sort((a, b) => c.sort(this.getValue(c, a), this.getValue(c, b)))
                    } else if (c.sort === "desc") {
                        this.tableData.sort((a, b) => {
                            a = this.getValue(c, a)
                            b = this.getValue(c, b)

                            if (typeof b !== "number") return -1
                            return b - a
                        })
                    } else if (c.sort === "asc") {
                        this.tableData.sort((a, b) => {
                            a = this.getValue(c, a)
                            b = this.getValue(c, b)

                            if (typeof a !== "number") return -1
                            return a - b
                        })
                    }
                }

                if (c.sum) {
                    var sum = 0

                    if (this.tableData?.length > 0) {
                        for (let di = 0, ds = this.tableData; di < ds.length; di++) {
                            let v = ds[di]
                            var value = this.getValue(c, v)

                            if (typeof value === "number") {
                                sum += value
                            }
                        }
                    }

                    this.sums[c.key] = sum
                }

                if (c.width) {
                    c._w = typeof c.width === "number" ? c.width + "px" : c.width
                    continue
                } else if (c.minWidth) {
                    c._w = typeof c.minWidth === "number" ? c.minWidth + "px" : c.minWidth
                    continue
                } else c._w = null

                c._w = this.getMinWidth(c.title)

                if (this.tableData?.length > 0) {
                    for (let di = 0, ds = this.tableData; di < ds.length; di++) {
                        let v = ds[di]
                        var value = c.key ? this.getValue(c, v) : null

                        if (value) {
                            let w = this.getMinWidth(value)

                            if (!c._w || c._w < w) {
                                c._w = w
                            }
                        }
                    }
                }

                if (!c._w) {
                    c._w = "200px"
                } else if (typeof c._w === "number") {
                    c._w = c._w + "px"
                }
            }
        },

        /**
         * 获取最小宽度值
         */
        getMinWidth(text) {
            // 非字符串则转换为字符串格式
            if (typeof text !== "string") text = String(text)
            // HTML 标签不处理，默认 200 px
            if (/^<.*\/.*>$/.test(text)) return 200
            // 普通字符 18 px，中文加多 10 px/字
            let size = text.length * 18 + (text.match(/[\u4E00-\u9FA5]/g)?.length || 0) * 10
            // 最小宽度为 80, 最大宽度为 400，超过就内容换行
            return size > 80 ? (size > 400 ? 400 : size) : 80
        },

        /**
         * 监听页面大小变化
         */
        onChangePageSize(evt) {
            this.req?.setIndex(this.currentPage, evt, this.currentPage === 1)
        },

        /**
         * 监听页码变化
         */
        onChangePage(evt) {
            this.req?.setIndex(evt)
        },

        /**
         * 获取单元格值
         */
        getValue(col, row) {
            var v = Utils.getStringObject(row, col.key || col.title)

            if (!v && col.render) {
                try {
                    v = col.render(null, { row })
                } catch {}
            }

            return typeof v === "number" ? v : v || "-"
        },

        /**
         * 获取单元格样式
         */
        getHighlightColor(col, row) {
            let v = this.getValue(col, row)

            if (typeof v === "number" && col.highlight && v > 0) {
                return this.color
            }

            return "#333333"
        },

        /**
         * 监听排序变化
         */
        onChangeSort(col, sort) {
            for (let i = 0, cs = this.columns; i < cs.length; i++) {
                const c = cs[i]

                if (c === col && c.sort !== sort) {
                    c.sort = sort
                } else if (c.sort) {
                    c.sort = true
                }
            }
            if (col.sort === true) {
                this.tableData = JSON.parse(JSON.stringify(this.cacheTableData))
            }
            // 处理数据
            this.onProcessData()
        },

        /**
         * 监听选择器变化事件
         */
        onChangeSelector(value, item) {
            if (this.isRadio) {
                return (this.selecteds = value ? [item] : [])
            }

            let s = this.selecteds
            let idx = s.findIndex(v => v._id === item._id)

            if (idx > -1) {
                s.splice(idx, 1)
            } else {
                s.push(item)
            }
        },

        /**
         * 监听全选
         */
        onChangeAllSelector() {
            if (this.tableData.length === this.selecteds.length) {
                return (this.selecteds = [])
            }

            this.selecteds = [].concat(this.tableData)
        },

        /**
         * 刷新
         */
        refresh() {
            this.req?.refresh()
        },

        search(data) {
            this.req?.setData(data, false)
        },

        reset() {
            if (this.copyTableData) {
                this.tableData = this.copyTableData
                this.copyTableData = null
            }

            this.req?.reset()
        },

        /**
         * 清空勾选
         */
        clearSelecteds() {
            this.selecteds = []
        },

        /**
         * 获取已选
         */
        getSelecteds() {
            return this.selecteds
        },

        keyword(t) {
            if (!t) {
                this.tableData = this.copyTableData || this.tableData
                return
            }

            if (!this.copyTableData) {
                this.copyTableData = this.tableData
            }

            const result = []

            Utils.each(this.copyTableData, l => {
                Utils.eachObj(l, (k, v) => {
                    if (v && String(v).indexOf(t) > -1 && !result.includes(l)) {
                        result.push(l)
                    }
                })
            })

            this.tableData = result
        },

        export() {
            const tableData = this.tableData
            let head = []
            let keys = []
            let joinKey = []

            for (let i = 0, l = this.columns; i < l.length; i++) {
                let v = l[i]

                if (v.content) {
                    let id = this.$core.randomString()
                    head.push(v.title)
                    keys.push(id)
                    tableData.forEach(val => {
                        val[id] = v.content(val)
                    })
                } else if (Array.isArray(v.key)) {
                    joinKey.push(v.key)
                    head.push(v.title)
                    keys.push(v.key.join("-"))
                } else if (v.key) {
                    head.push(v.title)
                    keys.push(v.key)
                }
            }

            joinKey.forEach(ks => {
                tableData.forEach(v => {
                    var vals = []
                    ks.forEach(k => {
                        v[k] && vals.push(v[k])
                    })
                    v[ks.join("-")] = vals.join(" - ")
                })
            })

            this.$core.exportExcel(head, keys, tableData, this.title || "数据导出")
        },

        getDataList() {
            return this.tableData
        },
    },
}
</script>

<style lang="less" scoped>
.elem-table {
    position: relative;
    border: 1px solid #f3f3f3;
    border-radius: 6px;
    overflow: hidden;
    min-height: 200px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;

    .title {
        position: sticky;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        padding: 15px 20px;
        box-sizing: border-box;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        flex-shrink: 0;
    }

    .table-box {
        position: relative;
        width: 100%;
        background: #fff;
        overflow: auto;
        flex-grow: 1;
        transition: all 0.1s;
        display: flex;
        flex-direction: column;

        &.loading {
            filter: blur(5px);
        }

        .table-con {
            flex-grow: 1;
        }

        .row {
            position: relative;
            min-width: 100%;
            display: flex;
            transition: all 0.1s;

            &:nth-child(2n + 1) {
                .col {
                    background: #f8f8f8;
                }
            }

            .col {
                min-height: 55px;
                padding: 12px 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                min-width: 100px;
                flex-shrink: 0;
                background: #fff;
                text-align: center;
                word-break: break-word;
                transition: all 0.2s;
                color: #666;
                border-bottom: 1px solid #f8f8f8;
                letter-spacing: 0.5px;
                box-sizing: border-box;

                &.float-left {
                    position: sticky;
                    left: 0;
                    z-index: 10;
                    flex-shrink: 0;
                    z-index: 5;
                    border-right: 1px solid #ededed;

                    .ivu-checkbox-wrapper {
                        margin-right: 0;
                    }
                }

                &.float-right {
                    position: sticky;
                    right: 0;
                    z-index: 10;
                    flex-shrink: 0;
                    z-index: 5;
                    border-left: 1px solid #ededed;
                }

                .radio {
                    /deep/.ivu-checkbox-inner {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;

                        &::after {
                            top: 3px;
                            left: 6px;
                            height: 9px;
                        }
                    }
                }
            }

            &:not(.head):hover {
                z-index: 10;

                .col {
                    background: #f3f3f3;
                }
            }

            &.head {
                position: sticky;
                top: 0;
                z-index: 20;

                .col {
                    background: #eee;
                    padding: 18px 20px;
                    font-weight: bold;
                    border-bottom: 0;

                    &.float-left,
                    &.float-right {
                        border-color: #e6e6e6;
                    }

                    .sort-box {
                        margin-left: 12px;
                        display: flex;
                        flex-direction: column;

                        div {
                            cursor: pointer;
                            width: 10px;
                            height: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .asc {
                            &::after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-bottom: 6px solid #d3d3d3;
                            }

                            &.ac {
                                &::after {
                                    border-bottom-color: red;
                                }
                            }
                        }

                        .desc {
                            &::after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-top: 6px solid #d3d3d3;
                            }

                            &.ac {
                                &::after {
                                    border-top-color: green;
                                }
                            }
                        }
                    }
                }
            }

            &.sum {
                position: sticky;
                bottom: 0;
                z-index: 10;

                .col {
                    background: #eee;
                    color: #555;
                    font-weight: bold;
                    border-color: #eee;
                }
            }
        }
    }

    .page-box {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
        background: #fff;
        border-top: 1px solid #f3f3f3;
    }

    .load-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        transition: all 0.2s;
        opacity: 0;
        z-index: 50;

        &.ac {
            visibility: initial;
            opacity: 1;
        }

        .ivu-spin-fix {
            background: rgba(255, 255, 255, 0.9);
        }
    }

    .empty-box {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 40px);
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888;
    }
}
</style>
